<template>
    <div class="grid grid-cols-2 gap-2">
        <slot></slot>
    </div>
    

</template>

<script lang="ts">
    export default defineNuxtComponent({
        name:'basetemplate-evelle2011-contact'
    })

</script>

<style>

</style>